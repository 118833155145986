import { IssuerService } from './../../issuer/services/issuer.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropdownService } from './../../../common/services/dropdown.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService} from 'ngx-toastr';
import { TseriesEndPoints } from 'src/app/constants/api-endpoints';
import { Issuer } from 'src/app/models/auxiliary/issuer.model';
import { ApiService } from 'src/app/sections/common/services/api.service';
import { TSeries } from 'src/app/models/auxiliary/tseries.model';
import { CommonUIService } from 'src/app/sections/common/services/common.ui.service';
import { SweetAlertActions } from 'src/app/constants/sweet-alert-actions';

@Injectable({
  providedIn: 'root'
})
export class SeriesGroupService {

  tseriesurls = TseriesEndPoints;

  yearsList: string[] = [];
  issuersList: Issuer[] = [];
  seriesList: TSeries[] = [];
  seriesClonedYearsList: string[] = [];
  selectedYear: string = '';
  defaultIssuer: Issuer;
  yearsLoading = true;
  seriesLoading = true;
  isCloned = false;

  constructor(
    private dropdownService: DropdownService, private issuerService: IssuerService, private commonUIService: CommonUIService,
    private toastService: ToastrService, private translationService: TranslateService, private _apiService: ApiService) {
    this.getIssuersList();
    this.getDistinctYearsList();
    this.getDefaultIssuer();
    this.getCloneDetails();
  }

  getIssuersList() {
    this.dropdownService.getIssuerList().subscribe(
      (data: any) => {
        if (data._data != null) {
          this.issuersList = data._data;
        }
      },
      (error) => {
        this.failToast(error._message);
      });
  }

  getDistinctYearsList() {
    this.yearsLoading = true;
    return this._apiService.get(this.tseriesurls.getDistinctYearsList, true, true).subscribe(
      (data: any) => {
        if (data._data != null) {
          this.yearsList = data._data;
          if (this.yearsList.length > 0) {
            this.selectedYear = this.yearsList.includes(new Date().getFullYear().toString())
              ? new Date().getFullYear().toString()
              : this.yearsList.find(year => new Date().getFullYear() > parseInt(year)) || this.yearsList[0];
            this.getTseriesListBySelectedYear();
            this.handleClone();

          }
        }
        this.yearsLoading = false;
        this.seriesLoading = false;
      },
      (error) => {
        this.failToast(error._message);
        this.yearsLoading = false;
      });
  }

  getCloneDetails() {
    return this._apiService.get(this.tseriesurls.getSeriesCloneDetail, true, true).subscribe(
      (data: any) => {
        if (data._data != null) {
          this.seriesClonedYearsList = data._data.map(y => y.year);
          this.handleClone();
        }
      },
      (error) => {
        this.failToast(error._message);
      });
  }

  handleClone() {

    const cloneableYears = this.yearsList.map(y => parseInt(y)).filter(y => y < new Date().getFullYear());
    if (
      this.seriesClonedYearsList.length > 0 && !this.isCloned && cloneableYears.length &&
      !this.seriesClonedYearsList.includes(new Date().getFullYear().toString())) {
      this.isCloned = true;
      this.commonUIService.sweetAlert('tseries.cloneSeries', 'tseries.colneTheSeries', null, null, { cancelButtonText: this.commonUIService.Translate('no') }).then((response: any) => {
        const status = response.action == SweetAlertActions.confirm;
        this.cloneTseries(Math.max(...cloneableYears).toString(), new Date().getFullYear().toString(), status).subscribe(
          (data: any) => {
            this.getTseriesListBySelectedYear();
          },
        );
      })
    }
  }

  getDefaultIssuer() {
    return this.issuerService.getDefaultIssuer().subscribe(
      (data: any) => {
        if (data._data != null) {
          this.defaultIssuer = data._data;
        }
      },
      (error) => {
        this.failToast(error._message);
      });
  }

  getTseriesListBySelectedYear() {
    if (this.selectedYear == '') {
      this.seriesLoading = false;
      return;
    }
    this.seriesLoading = true;
    this.seriesList = [];
    return this._apiService.get(this.tseriesurls.getTseriesByYear.replace("{year}", this.selectedYear), true, true).subscribe(
      (response: any) => {
        const data = this.returnResponse(response);
        if (data._data) {
          this.seriesList = data._data;
        }
        this.seriesLoading = false;
      },
      (error) => {
        this.failToast(error._message);
        this.yearsLoading = false;
      });
  }

  saveTseries(tSeries: TSeries): Observable<TSeries> {
    return this._apiService.post(this.tseriesurls.saveTseries, tSeries, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateTseries(id: number, tSeries: TSeries): Observable<TSeries> {
    return this._apiService.put(this.tseriesurls.updateTseries + id, tSeries, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteTseries(id: number): Observable<TSeries> {
    return this._apiService.delete(this.tseriesurls.deleteTseries, id.toString(), true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  cloneTseries(fromYear: string, toYear: string, isCloned: boolean): Observable<TSeries> {
    let url = this.tseriesurls.cloneSeries;
    url = url
      .replace('{fromYear}', fromYear)
      .replace('{toYear}', toYear)
      .replace('{isCloned}', isCloned.toString());
    return this._apiService.post(url, null, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  failToast(message) {
    this.translationService.get(['errorTitle']).subscribe((translations: any) => {
      //const toastOptions: ToastOptions = {
      //   theme: 'bootstrap'
      // };
      // this.toastService.error(toastOptions);
        //title: 'Error',
       // msg: message,
       // showClose: true,
       // timeout: 5000,
      //  theme: 'bootstrap'
   //  };
    //  this.toastService.error(toastOptions);
    });
  }
}
