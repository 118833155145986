import { CollegeProvince } from './../../../models/college/college-province-model';
import { CommonUIService } from 'src/app/sections/common/services/common.ui.service';
import { CompanyLogoConfiguration } from 'src/app/models/auxiliary/company-logo-configuration';
import { AuxillaryService } from 'src/app/sections/common/services/auxillary.service';
import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import { CollegeDocumentEndPoints, CollegeEndPoints, CollegeObservationEndPoints, CollegeProvinceEndPoints, CompanyLogoEndPoints } from 'src/app/constants/api-endpoints';
import { map } from 'rxjs/operators';
import { College } from 'src/app/models/college/college-model';
import { Observable } from 'rxjs';
import { CollegeObservation } from 'src/app/models/college/college-observation-model';
import { CollegeDocument } from 'src/app/models/college/college-document-model';
import { HttpEventType } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CollegeDataPageService {

  collegeUrl = CollegeEndPoints;
  collegeObservationUrl = CollegeObservationEndPoints;
  collegeDocumentUrl = CollegeDocumentEndPoints;
  companyLogoUrls = CompanyLogoEndPoints;
  collegeProvinceUrl = CollegeProvinceEndPoints;

  isLoading = false;
  showCollegeLogoPanel = false;
  showPresidentLogoPanel = false;
  showSecretaryLogoPanel = false;
  showAttachmentsPanel = false;
  showObservationsPanel = false;
  showProvincesPanel = false;

  public college: College = new College();
  public collegeLogo: CompanyLogoConfiguration = new CompanyLogoConfiguration();
  public secretaryLogo: CompanyLogoConfiguration = new CompanyLogoConfiguration();
  public presidentLogo: CompanyLogoConfiguration = new CompanyLogoConfiguration();

  constructor(private _apiService: ApiService, private auxillaryService: AuxillaryService, private commonUIService: CommonUIService) {
    this.getCollegeDataList();
  }

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  // College Data
  saveCollege(college: College): Observable<College> {
    return this._apiService.post(this.collegeUrl.saveCollege, college, true, true).pipe(
      map((response: any) => {
        this.getCollegeDataList();
        return this.returnResponse(response);
      }));
  }

  updateCollege(college: College): Observable<College> {
    return this._apiService.put(this.collegeUrl.updateCollege + this.college.id, college, true, true).pipe(
      map((response: any) => {
        this.getCollegeDataList();
        return this.returnResponse(response);
      }));
  }

  getCollegeDataList(): void {
    this.isLoading = true
    this._apiService.get(this.collegeUrl.getCollegeList, true, true).subscribe(
      (data: any) => {
        if (data._data != null) {
          this.college = data._data.length ? data._data[0] : new College();
          this.college.companyLogoConfiguration.map((element) => {
            if (element.imagePathUrl != null || element.imagePathUrl != undefined) {
              element.imageData = 'data:image/png;base64,' + element.imagePathUrl;
            } else {
              element.imageData = undefined;
            }
          });
          this.collegeLogo = this.college.companyLogoConfiguration.find(l => l.logoKey == "college_logo");
          this.presidentLogo = this.college.companyLogoConfiguration.find(l => l.logoKey == "president_logo");
          this.secretaryLogo = this.college.companyLogoConfiguration.find(l => l.logoKey == "secretary_logo");

          this.showCollegeLogoPanel = this.showCollegeLogoPanel || !!this.collegeLogo;
          this.showPresidentLogoPanel = this.showPresidentLogoPanel || !!this.presidentLogo;
          this.showSecretaryLogoPanel = this.showSecretaryLogoPanel || !!this.secretaryLogo;
          this.showAttachmentsPanel = this.showAttachmentsPanel || this.college.collegeDocument.length > 0;
          this.showObservationsPanel = this.showObservationsPanel || this.college.collegeObservation.length > 0;
          this.showProvincesPanel = this.showProvincesPanel || this.college.collegeProvinces.length > 0;
          this.isLoading = false;
        }
      }, () => {
        this.isLoading = false;
      });
  }

  // College Observation
  saveCollegeObservation(collegeObservation: CollegeObservation): Observable<CollegeObservation> {
    return this._apiService.post(this.collegeObservationUrl.saveCollegeObservation, collegeObservation, true, true).pipe(
      map((response: any) => {
        this.getCollegeDataList();
        return this.returnResponse(response);
      }));
  }

  updateCollegeObservation(collegeObservation: CollegeObservation): Observable<CollegeObservation> {
    return this._apiService.put(this.collegeObservationUrl.updateCollegeObservation + collegeObservation.id, collegeObservation, true, true).pipe(
      map((response: any) => {
        this.getCollegeDataList();
        return this.returnResponse(response);
      }));
  }

  deleteCollegeObservation(id: number): Observable<CollegeObservation> {
    return this._apiService.delete(this.collegeObservationUrl.updateCollegeObservation, id.toString(), true).pipe(
      map((response: any) => {
        this.getCollegeDataList();
        return this.returnResponse(response);
      }));
  }

  // College Document
  addCollegeDocument(collegeDocument: CollegeDocument): Observable<CollegeDocument> {
    return this._apiService.post(this.collegeDocumentUrl.saveCollegeDocument, collegeDocument, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  uploadCollegeDocument(formData: FormData) {
    return this._apiService.upload(this.collegeDocumentUrl.uploadCollegeDocument, formData).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteCollegeDocument(id: number): Observable<CollegeDocument> {
    return this._apiService.delete(this.collegeDocumentUrl.updateCollegeDocument, id.toString(), true).pipe(
      map((response: any) => {
        this.getCollegeDataList();
        return this.returnResponse(response);
      }));
  }



  // College Province
  saveCollegeProvince(collegeProvince: CollegeProvince): Observable<CollegeProvince> {
    return this._apiService.post(this.collegeProvinceUrl.saveCollegeProvince, collegeProvince, true, true).pipe(
      map((response: any) => {
        this.getCollegeDataList();
        return this.returnResponse(response);
      }));
  }

  updateCollegeProvince(collegeProvince: CollegeProvince): Observable<CollegeProvince> {
    return this._apiService.put(this.collegeProvinceUrl.updateCollegeProvince + collegeProvince.id, collegeProvince, true, true).pipe(
      map((response: any) => {
        this.getCollegeDataList();
        return this.returnResponse(response);
      }));
  }

  deleteCollegeProvince(id: number): Observable<CollegeProvince> {
    return this._apiService.delete(this.collegeProvinceUrl.updateCollegeProvince, id.toString(), true).pipe(
      map((response: any) => {
        this.getCollegeDataList();
        return this.returnResponse(response);
      }));
  }

  // Logo
  uploadLogo(files, logoKey) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      let fileName = "";
      let extension = "";
      let generateFileName = "";
      for (let file of files) {
        fileName = file.name;
        extension = fileName.split('.').pop();
        generateFileName = this.commonUIService.generateUuid(false) + '.' + extension;
        formData.append(file.name, file, generateFileName);
        formData.append('folderName', 'CollegeLogos');
      }
      this._apiService.upload(this.companyLogoUrls.uploadCompanyLogo, formData)
        .subscribe(res => {
          if (res.type === HttpEventType.Response) {
            if (res.type === 4) {
              let selectedCompanyLogoConfiguration = logoKey == "secretary_logo" ? this.secretaryLogo : logoKey == "president_logo" ? this.presidentLogo : this.collegeLogo;
              if (!selectedCompanyLogoConfiguration) selectedCompanyLogoConfiguration = new CompanyLogoConfiguration();
              selectedCompanyLogoConfiguration.collegeId = this.college.id;
              selectedCompanyLogoConfiguration.logoKey = logoKey;
              selectedCompanyLogoConfiguration.image = generateFileName;
              selectedCompanyLogoConfiguration.name = fileName;
              const updateOrSave = selectedCompanyLogoConfiguration.id > 0
                ? this.auxillaryService.updateCompanyLogo(selectedCompanyLogoConfiguration.id, selectedCompanyLogoConfiguration)
                : this.auxillaryService.saveCompanyLogo(selectedCompanyLogoConfiguration);

              updateOrSave.subscribe(
                (_data: any) => {
                  this.getCollegeDataList();
                  resolve(_data);
                },
                (error) => {
                  reject(error);
                }
              );
            }
          }
        },
          (error) => {
            reject(error);
          }
        );
    });

  }

  removeLogo(logoKey) {
    return new Promise((resolve, reject) => {
      let selectedCompanyLogoConfiguration = logoKey == "secretary_logo" ? this.secretaryLogo : logoKey == "president_logo" ? this.presidentLogo : this.collegeLogo;
      this._apiService.delete(this.companyLogoUrls.deleteCompanyLogo, selectedCompanyLogoConfiguration.id.toString(), true).subscribe(
        (_data: any) => {
          this.getCollegeDataList();
          resolve(_data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

}
