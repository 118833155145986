import { Country } from '../country.model';
import { Province } from '../doctor/province.model';
import { Town } from '../doctor/doctor-town.model';
import { CollegeDocument } from './college-document-model';
import { CollegeObservation } from './college-observation-model';
import { CompanyLogoConfiguration } from '../auxiliary/company-logo-configuration';
import { CollegeProvince } from './college-province-model';

export class College {
    id: number;
    name: string;
    nif: string;
    president: string;
    secretary: string;
    address: string;
    no: string;
    floor: string;
    townId:number;
    provinceId:number;
    countryId:number;
    phone: string;
    whatsapp: string;
    email: string;
    web: string;

    country: Country;
    province: Province;
    town: Town;
    collegeDocument: CollegeDocument[];
    collegeObservation: CollegeObservation[];
    companyLogoConfiguration: CompanyLogoConfiguration[];
    collegeProvinces: CollegeProvince[];

    constructor() {
        this.town = new Town();
        this.country = new Country();
        this.province = new Province();
        this.collegeDocument = [] as CollegeDocument[];
        this.collegeObservation = [] as CollegeObservation[];
        this.companyLogoConfiguration = [] as CompanyLogoConfiguration[];
        this.collegeProvinces = [] as CollegeProvince[];
    }
}
