import { SweetAlertActions } from './../../../constants/sweet-alert-actions';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateFormat } from '../date-format';
import { EmailEndPoints } from '../../../constants/api-endpoints';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { SendEmail } from 'src/app/models/common/SendEmail';
import { Subject } from 'rxjs';
import Swal, { SweetAlertOptions, SweetAlertType } from 'sweetalert2';
import * as moment from 'moment';
import * as htmlToPdf from 'html2pdf.js';
import { v4 as uuid } from 'uuid';
import { FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CommonUIService {
  isSpinnerVisible = false;
  IsExanded = false;
  IsLeftSideVisible = true;
  isSidebarDisabled = false;
  isActiveLeftPanelOnly = false;
  isActiveRightPanelOnly = false;
  isActiveBothPanel = true;
  isActiveStatisticPanel = false;

  isShowLoyoutMenu = false;
  isShowStatMenu = false;
  isShowExpandView = false;

  isPopupOpened = false;
  isPreviewDocumentOpened = false;
  imageData: string;

  private classDataSubject = new Subject<any>();
  classState = this.classDataSubject.asObservable();

  validationToasters: ActiveToast<any>[] = [];

  @Output() expandView = new EventEmitter();

  // ngbDatepicker variables
  public minDate: {};
  public maxDate: {};
  public maxAdjustmentDate: {};

  emailUrls = EmailEndPoints;

  constructor(
    private http: HttpClient,
    private _translateService: TranslateService,
    private _toastrService: ToastrService
  ) {
    this.initializeDateVariables();
  }

  addClass(isClassAdd: boolean) {
    this.classDataSubject.next(isClassAdd);
  }

  openModal(element) {
    const popup = document.querySelector('#' + element);
    if (!popup) {
      console.error('cannot find modal');
      return;
    }
    popup.classList.add('md-show');
  }

  OpenBasicModal(element) {
    document.querySelector('#' + element).classList.add('show-modal');
  }

  closeModal(event) {
    event.target.parentElement.parentElement.parentElement.parentElement.classList.remove(
      'md-show'
    );
  }

  CloseModalEx(modal: string) {
    try {
      const domElement = document.getElementById(modal);
      if (domElement) {
        domElement.classList.remove('md-show');
      }
    } catch (error) {
      console.log(error);
    }
  }

  CloseModal(event) {
    this.isPopupOpened = false;
    event.path[6].classList.remove('md-show');
  }

  ClosePreviewModal(event) {
    event.path[5].classList.remove('md-show');
  }

  ConvertToPickerDateFormat(dateString: string): DateFormat {
    if (dateString) {
      let formatedDate: string = dateString;
      if (formatedDate !== undefined || formatedDate != null) {
        formatedDate = formatedDate.substring(0, 10);
        const splitted_array: string[] = formatedDate.split('-');
        // tslint:disable-next-line: radix
        return new DateFormat(
          parseInt(splitted_array[2]),
          parseInt(splitted_array[1]),
          parseInt(splitted_array[0])
        );
      }
    }
  }

  ConvertDateFormatToString(dateObject: DateFormat): string {
    if (dateObject !== undefined) {
      const month = ('0' + dateObject.month).slice(-2);
      const day = ('0' + dateObject.day).slice(-2);
      const dateString = dateObject.year + '-' + month + '-' + day;
      return moment(dateString, 'YYYY-MM-DD').format('YYYY-MM-DD');
    }
  }

  ChangeNgbDateToCustomFormat(ngbDate): any {
    if (ngbDate && ngbDate.year) {
      return (
        ngbDate.year +
        '-' +
        this.GetWholeNumber(ngbDate.month.toString()) +
        '-' +
        this.GetWholeNumber(ngbDate.day.toString())
      );
    }
    return ngbDate;
  }
  
  validateNgbDate(formControl: FormControl, showRequiredValidation = false) {
    let date = formControl.value;

    if (showRequiredValidation && !date) {
      formControl.setErrors({ required: true });
      return;
    }
    
    // Regular expressions
    const validCharactersRegex = /^[0-9/]*$/;
    const dateFormatRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/; // Ensure the year has 4 digits
  
    // Check for invalid characters
    if (typeof date === 'string' && !validCharactersRegex.test(date)) {
      formControl.setErrors({ invalidDate: true });
      return;
    }
  
    // Check for "dd/mm/yyyy" format
    if (typeof date === 'string' && dateFormatRegex.test(date)) {
      const [day, month, year] = date.split('/').map(Number);
  
      // Ensure the year is four digits
      if (year < 1000 || year > 9999) {
        formControl.setErrors({ invalidDate: true });
        return;
      }
  
      if (day > 31 || month > 12 || year < 1000 || year > 9999) {
        formControl.setErrors({ invalidDate: true });
        return;
      }
  
      // Valid date in "dd/mm/yyyy" format, convert to NgbDate
      formControl.setValue(new NgbDate(year, month, day));
      return;
    }
  
    // Check for "ddmmyyyy" or other valid numeric formats
    if (typeof date === 'string' && date.length === 8 && !isNaN(Number(date))) {
      const day = Number(date.slice(0, 2));
      const month = Number(date.slice(2, 4));
      const year = Number(date.slice(4, 8));
  
      // Ensure the year is four digits
      if (year < 1000 || year > 9999) {
        formControl.setErrors({ invalidDate: true });
        return;
      }
  
      if (day > 31 || month > 12 || year < 1000 || year > 9999) {
        formControl.setErrors({ invalidDate: true });
        return;
      }
  
      // Valid date in compact format, convert to NgbDate
      formControl.setValue(new NgbDate(year, month, day));
      return;
    }
  
    // Check for NgbDate object validity
    if (typeof date === 'object' && date.year && date.month && date.day) {
      const { year, month, day } = date;
      if (
        year.toString().length !== 4 ||
        month < 1 || month > 12 ||
        day < 1 || day > 31
      ) {
        formControl.setErrors({ invalidDate: true });
        return;
      }
  
      // Already a valid NgbDate
      return;
    }
  
    // Invalid format or unrecognized input
    formControl.setErrors({ invalidDate: true });
  }  

  dateToCustomFormat(date: Date): any {
    return (
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1) +
      '-' +
      date.getDate()
    );
  }

  // ToNgbDate(date: Date): NgbDate {
  //   return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  // }

  private GetWholeNumber(num: string) {
    if (num) {
      if (num.length === 1) {
        num = num.padStart(2, '0');
      }
      return num;
    }
  }

  // openSuccessSwal(_title: string, _text: string) {
  //   swal({
  //     title: _title,
  //     text: _text,
  //     type: 'success',
  //     // tslint:disable-next-line: deprecation
  //   }).catch(swal.noop);
  // }


  // HTML report
  PrintHtmlReport(section: string): void {
    let popupWindow: Window;
    const html: any = document.getElementById(section).innerHTML;
    if (html) {
      popupWindow = window.open(
        '',
        '_blank',
        'top=0,left=0,height=' + screen.height + ',width= ' + screen.width
      );
      if (popupWindow) {
        popupWindow.document.open();
        popupWindow.document.write(`
            <html>
              <head>
                <title></title>
                <style>
                @page { size: auto;  margin-top: 0mm; }
                @media print, screen{
                body{font-family:"Roboto", sans-serif;}
                .row{display:flex;flex-wrap:wrap;margin-right:-15px;margin-left:-15px;}
                .container-fluid{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;}
                .col {padding-right: 15px; padding-left: 15px; box-sizing: border-box;}
                .col-md-2 { flex: 0 0 16.666667%; max-width: 16.666667%;}
                .col-md-3 {flex: 0 0 25%; max-width: 25%;}
                .col-md-4 {flex: 0 0 33.333333%; max-width: 33.333333%; }
                .col-md-5 {flex: 0 0 41.666667%; max-width: 41.666667%;}
                .col-md-6 { flex: 0 0 50%;max-width: 50%;}
                .col-md-7 { flex: 0 0 58.333333%;max-width: 58.333333%; }
                .col-md-8 {flex: 0 0 66.666667%;max-width: 66.666667%;}
                .col-md-9 {flex: 0 0 75%;max-width: 75%; }
                .col-md-10 {flex: 0 0 83.333333%; max-width: 83.333333%; }
                .col-md-11 {flex: 0 0 91.666667%; max-width: 91.666667%; }
                .col-md-12{flex:0 0 100%;max-width:100%;}
                .d-flex {display: flex;}
                .justify-content-center {justify-content: center;}
                .justify-content-end {justify-content: end;}
                .align-items-center {align-items: center;}
                .flex-wrap {flex-wrap: wrap;}
                .w300{ width:300px;}
                .w400{ width:400px;}
                .w450{ width:450px;}
                .w200{ width:200px;}
                .w250{ width:250px;}
                .min-w80{ min-width:80px;}
                .min-w120{ min-width:120px;}
                .min-w250{ min-width:250px;}
                .text-uppercase{text-transform:uppercase !important;}
                .float-left{float:left;}
                .float-right{float:right;}
                .f-14{font-size:14px;}
                .f-13{font-size:13px;}
                .f-15{font-size:15px;}
                .f-12{font-size:12px;}
                .f-20{font-size:20px;}
                .text-blue{color:#265b9e;-webkit-print-color-adjust:exact; }
                .text-white{color:#fff;-webkit-print-color-adjust:exact;}
                .text-darkblue{color: #314577;-webkit-print-color-adjust:exact;}
                .font-weight-bold{font-weight:700 !important;}
                .font-italic{font-style:italic;}
                .text-center{text-align:center !important;}
                .text-right{text-align:right !important;}
                .text-left{text-align:left !important;}
                .pg-break-after{page-break-after: always;}
                .table{width:100%;border-collapse: collapse;}
                 .border-bottom-dash{border-bottom:1px dashed #314577;}
                 .border-top-dash{border-top:1px dashed #314577;}
                strong{font-weight:bold;}
                .p-0{ padding:0px;}
                .p-2{padding:2px;}
                .p-5{padding:5px;}
                .p-10{padding:10px;}
                .p-20{padding:20px;}
                .p-l-5{padding-left:5px;}
                .p-l-10{padding-left:10px;}
                .p-l-15{padding-left:15px;}
                .p-r-10{padding-right:10px !important;}
                .p-r-15{padding-right:15px !important;}
                .p-r-20{padding-right:20px !important;}
                .p-r-30{padding-right:30px !important;}
                .pl-0{padding-left:0px !important;}
                .pr-0{padding-right:0px !important;}
                .p-t-5{padding-top:5px;}
                .p-t-10{padding-top:10px;}
                .p-b-5{padding-bottom:5px;}
                .p-b-10{padding-bottom:10px;}
                .p-b-20{padding-bottom: 20px !important;}
                .m-t-30{margin-top:30px !important;}
                .m-t-60{margin-top:60px !important;}
                .my-7{margin-bottom:7px !important; margin-top:7px !important;}
                .m-5{margin:5px;}
                .m-l-15{margin-left:15px !important;}
                .m-l-10{margin-left:10px !important;}
                .m-r-10{margin-right:10px !important;}
                .m-r-12{margin-right:12px !important;}
                .print-m-r-15{margin-right:15px !important;}
                .m-0{ margin:0px;}
                .m-b-0{margin-bottom: 0px !important;}
                .m-b-5{margin-bottom:5px !important;}
                .m-b-20{margin-bottom:20px !important;}
                .m-t-0{margin-top: 0px !important;}
                .m-t-5{margin-top:5px;}
                .m-t-20{margin-top:20px !important;}
                .m-t-10{margin-top:10px !important; }
                .m-t-15{margin-top:15px;}
                .m-l-5{margin-left:5px !important}
                .mr-2{margin-right:10px;}
                .mr-15{margin-right:15px;}
                table{width:100%;}
                .bg3{background: #314577; -webkit-print-color-adjust: exact;}
                .bg5 {background-color: #def6ff !important; -webkit-print-color-adjust: exact;}
                .table > thead.bg3 > tr > th { color: #ffffff; -webkit-print-color-adjust: exact; text-align:left; padding-left:5px;}
                .title-blue{ color: #314577;}
                .logo-title{color:#52beea;}
                td{vertical-align:top;}
                td{padding-left:5px;}
                .border-bottom-dash{border-bottom:1px dashed #314577;}
                .border-top-dash{border-top:1px dashed #314577;}
                .report-logo-w{width:25%;}
                .break-line{word-wrap: break-word;}
                .minus-price-no{
                  letter-spacing: 1.5px;
                }
                img.college-logo {max-width: 200px; max-height: 80px;}
                .text-danger{color:#dc3545;}
                </style>
              </head>
              <body>${html}</body>
            </html>`);


        // chrome browser fix
        popupWindow.setTimeout(function () {
          popupWindow.focus(); // necessary for IE >= 10
          popupWindow.stop();
          popupWindow.print();
        }, 1000)
      }
    }
  }

  // email styles
  convertHtmlToPdfBase64(html) {
    htmlToPdf().from(html).save();
    htmlToPdf().from(html).outputPdf()
  }


  CloseBasicModal(event) {
    document.querySelector('#' + event).classList.remove('show-modal');
  }

  // LoadLabConfigurationList(): Promise<string> {
  //   return new Promise<string>((resolve, reject) => {
  //     this._apiService.get(API.lab_configuration.loadLogoList).subscribe(
  //       (res) => {
  //         if (res.length > 0) {
  //           res.forEach((element) => {
  //             if (
  //               element.imagePathUrl !== null ||
  //               element.imagePathUrl !== undefined
  //             ) {
  //               this.imageData =
  //                 'data:image/png;base64,' + element.imagePathUrl;
  //             } else {
  //               this.imageData = undefined;
  //             }
  //             resolve(this.imageData);
  //           });
  //         } else {
  //           resolve(this.imageData);
  //         }
  //       },
  //       (err) => reject(err)
  //     );
  //   });
  // }

  // Email styles
  async ConvertHtmlToPdfBase64(section: string) {
    const html: any = document.getElementById(section).innerHTML;
    if (html) {
      const template = `
      <html>
      <head>
        <title></title>
        <style>
        @page { size: auto;  margin-top: 0mm; }
        @media print, screen{
        body{font-family:"Heebo", sans-serif;}
        .row{display:flex;flex-wrap:wrap;margin-right:-15px;margin-left:-15px;}
        .container-fluid{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;}
        .col-md-2 { flex: 0 0 16.666667%; max-width: 16.666667%;}
        .col-md-3 {flex: 0 0 25%; max-width: 25%;}
        .col-md-4 {flex: 0 0 33.333333%; max-width: 33.333333%; }
        .col-md-5 {flex: 0 0 41.666667%; max-width: 41.666667%;}
        .col-md-6 { flex: 0 0 50%;max-width: 50%;}
        .col-md-7 { flex: 0 0 58.333333%;max-width: 58.333333%; }
        .col-md-8 {flex: 0 0 66.666667%;max-width: 66.666667%;}
        .col-md-9 {flex: 0 0 75%;max-width: 75%; }
        .col-md-10 {flex: 0 0 83.333333%; max-width: 83.333333%; }
        .col-md-11 {flex: 0 0 91.666667%; max-width: 91.666667%; }
        .col-md-12{flex:0 0 100%;max-width:100%;}
        .w300{ width:300px;}
        .w400{ width:400px;}
        .w450{ width:450px;}
        .w200{ width:200px;}
        .w250{ width:250px;}
        .text-uppercase{text-transform:uppercase !important;}
        .float-left{float:left;}
        .float-right{float:right;}
        .f-14{font-size:14px;}
        .f-13{font-size:13px;}
        .f-15{font-size:15px;}
        .f-12{font-size:12px;}
        .f-20{font-size:20px;}
        .text-blue{color:#265b9e;-webkit-print-color-adjust:exact; }
        .text-white{color:#fff;-webkit-print-color-adjust:exact;}
        .text-darkblue{color: #314577;-webkit-print-color-adjust:exact;}
        .font-weight-bold{font-weight:700 !important;}
        .font-italic{font-style:italic;}
        .text-center{text-align:center !important;}
        .text-right{text-align:right !important;}
        .text-left{text-align:left !important;}
        .pg-break-after{page-break-after: always;}
        .table{width:100%;border-collapse: collapse;}
         .border-bottom-dash{border-bottom:1px dashed #314577;}
         .border-top-dash{border-top:1px dashed #314577;}
        strong{font-weight:bold;}
        .p-0{ padding:0px;}
        .p-2{padding:2px;}
        .p-5{padding:5px;}
        .p-10{padding:10px;}
        .p-20{padding:20px;}
        .p-l-5{padding-left:5px;}
        .p-l-10{padding-left:10px;}
        .p-l-15{padding-left:15px;}
        .p-r-10{padding-right:10px !important;}
        .p-r-15{padding-right:15px !important;}
        .p-r-20{padding-right:20px !important;}
        .p-r-30{padding-right:30px !important;}
        .pl-0{padding-left:0px !important;}
        .pr-0{padding-right:0px !important;}
        .p-t-5{padding-top:5px;}
        .p-t-10{padding-top:10px;}
        .p-b-5{padding-bottom:5px;}
        .p-b-10{padding-bottom:10px;}
        .p-b-20{padding-bottom: 20px !important;}
        .m-t-30{margin-top:30px !important;}
        .m-t-60{margin-top:60px !important;}
        .my-7{margin-bottom:7px !important; margin-top:7px !important;}
        .m-5{margin:5px;}
        .m-l-15{margin-left:15px !important;}
        .m-l-10{margin-left:10px !important;}
        .m-r-10{margin-right:10px !important;}
        .m-r-12{margin-right:12px !important;}
        .m-r-15{margin-right:15px !important;}
        .m-0{ margin:0px;}
        .m-b-0{margin-bottom: 0px !important;}
        .m-b-5{margin-bottom:5px !important;}
        .m-b-20{margin-bottom:20px !important;}
        .m-t-0{margin-top: 0px !important;}
        .m-t-5{margin-top:5px;}
        .m-t-20{margin-top:20px !important;}
        .m-t-10{margin-top:10px !important; }
        .m-t-15{margin-top:15px;}
        .m-l-5{margin-left:5px !important}
        .mr-2{margin-right:10px;}
        .mr-15{margin-right:15px;}
        table{width:100%;}
        .bg3{background: #314577; -webkit-print-color-adjust: exact;}
        .bg5 {background-color: #def6ff !important; -webkit-print-color-adjust: exact;}
        .table > thead.bg3 > tr > th { color: #ffffff; -webkit-print-color-adjust: exact; text-align:left; padding-left:5px;}
        .title-blue{ color: #314577;}
        .logo-title{color:#52beea;}
        td{vertical-align:top;}
        td{padding-left:5px;}
        .border-bottom-dash{border-bottom:1px dashed #314577;}
        .border-top-dash{border-top:1px dashed #314577;}
        .report-logo-w{width:25%;}
        .break-line{word-wrap: break-word;}
        .minus-price-no{
          letter-spacing: 1.5px;
        }
      }

        </style>
      </head>
      <body class="print-div">${html}</body>
    </html>`;

      return htmlToPdf()
        .set({
          margin: 0.5,
          filename: 'my_file.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { dpi: 192, scale: 2, letterRendering: true },
          jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
        })
        .from(template)
        .outputPdf()
        .then((pdf) => (btoa(pdf)))
        .catch(() => btoa(''));
    }
  }

  //check this - need to fix this
  sendEmailRequest(emailmodel: SendEmail) {
    return this.http
      .post<SendEmail>(this.emailUrls.sendEmail, emailmodel)
      .pipe(
        map((response: any) => {
          return this.returnResponse(response);
        }));
  }

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }
  initializeDateVariables() {
    this.maxDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    };
    this.minDate = {
      year: new Date().getFullYear() - 100,
      month: 1,
      day: 1
    };
    this.maxAdjustmentDate = {
      year: new Date().getFullYear() + 1,
      month: 1,
      day: 1
    };
  }

  // ConvertStringToNgbDate(dateString: string) {
  //   if (dateString != null && dateString.length === 8 && Number(dateString)) {
  //     const date = dateString.slice(0, 2);
  //     const month = dateString.slice(2, 4);
  //     const year = dateString.slice(4, 8);
  //     let dateObj = new NgbDate(0, 0, 0);
  //     if (Number(date) > 31) {
  //       return null;
  //     } else if (Number(month) > 12) {
  //       return null;
  //     } else {
  //       return dateObj = new NgbDate(Number(year), Number(month), Number(date));
  //     }
  //   } else {
  //     return null;
  //   }
  // }

  public formatHourMinute(number: any) {
    const hours = parseInt(String(number), 10);
    // tslint:disable-next-line: radix
    const minutes = parseInt((number + '').split(':')[1]);
    return hours + 'h' + ' ' + minutes + 'm';
  }

  public formatTotalHourMinute(number: any) {
    let hours = 0;
    let calculatedMinutes = '00';
    if (number && number !== '') {
      hours = parseInt(String(number), 10);
      const tempNo = (number + '').split('.')[1];
      let floatingNumbers = 0;
      if (tempNo) {
        // tslint:disable-next-line: radix
        floatingNumbers = parseFloat('0.' + tempNo);
      }
      const tempNoSp = (number + '').split(',')[1];
      if (tempNoSp) {
        // tslint:disable-next-line: radix
        floatingNumbers = parseFloat('0.' + tempNoSp);
      }

      if (floatingNumbers === 0) {
        return hours + ' h' + ' ' + calculatedMinutes + ' min';
      }

      if (!isNaN(floatingNumbers)) {
        const minutes = Math.round(floatingNumbers * 60);
        if (!isNaN(minutes)) {
          calculatedMinutes = ('0' + minutes).slice(-2);
        }
        return hours + ' h' + ' ' + calculatedMinutes + ' min';
      } else {
        return hours + ' h' + ' ' + calculatedMinutes + ' min';
      }
    } else {
      return hours + ' h' + ' ' + calculatedMinutes + ' min';
    }
  }

  public Translate(text: string): string {
    let translatedString = "";
    this._translateService.get(text).subscribe(async (translation: string) => {
      translatedString = translation;
    });

    return translatedString;
  }

  async sweetAlert(titleKey?: string, messageKey?: string, type?: SweetAlertType, data?: any, options?: SweetAlertOptions) {
    let swalOptions: SweetAlertOptions = {
      type: "question",
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.Translate('yes'),
      cancelButtonText: this.Translate('common.cancel'),
    };
    if (titleKey) swalOptions.titleText = this.Translate(titleKey);
    if (messageKey) swalOptions.text = this.Translate(messageKey);
    if (type) swalOptions.type = type;
    if (options) swalOptions = { ...swalOptions, ...options };
    return new Promise((resolve, reject) => {
      Swal.fire(swalOptions).then((confirm) => {
        return resolve({ action: confirm.value ? SweetAlertActions.confirm : SweetAlertActions[confirm.dismiss], data: data });
      }).catch(err => reject(err));
    });
  }

  // email styles
  convertHtmlToPdfBase64Preview(html) {
    var content = document.getElementById(html).innerHTML;
    var contentFull = `
    <html>
      <head>
        <title></title>

  <link rel="stylesheet" href="https://unpkg.com/ng2-toasty@4.0.0/style.css">
        <link href="https://fonts.googleapis.com/css?family=Heebo&display=swap" rel="stylesheet">
        <style>
        @page { size: auto;  margin-top: 0mm; }
        @media print, screen{
        body{font-family:"Heebo", sans-serif;}
        .row{display:flex;flex-wrap:wrap;margin-right:-15px;margin-left:-15px;}
        .container-fluid{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;}
        .col-md-2 { flex: 0 0 16.666667%; max-width: 16.666667%;}
        .col-md-3 {flex: 0 0 25%; max-width: 25%;}
        .col-md-4 {flex: 0 0 33.333333%; max-width: 33.333333%; }
        .col-md-5 {flex: 0 0 41.666667%; max-width: 41.666667%;}
        .col-md-6 { flex: 0 0 50%;max-width: 50%;}
        .col-md-7 { flex: 0 0 58.333333%;max-width: 58.333333%; }
        .col-md-8 {flex: 0 0 66.666667%;max-width: 66.666667%;}
        .col-md-9 {flex: 0 0 75%;max-width: 75%; }
        .col-md-10 {flex: 0 0 83.333333%; max-width: 83.333333%; }
        .col-md-11 {flex: 0 0 91.666667%; max-width: 91.666667%; }
        .col-md-12{flex:0 0 100%;max-width:100%;}
        .w300{ width:300px;}
        .w400{ width:400px;}
        .w450{ width:450px;}
        .w200{ width:200px;}
        .w250{ width:250px;}
        .text-uppercase{text-transform:uppercase !important;}
        .float-left{float:left;}
        .float-right{float:right;}
        .f-14{font-size:14px;}
        .f-13{font-size:13px;}
        .f-15{font-size:15px;}
        .f-12{font-size:12px;}
        .f-20{font-size:20px;}
        .text-blue{color:#265b9e;-webkit-print-color-adjust:exact; }
        .text-white{color:#fff;-webkit-print-color-adjust:exact;}
        .text-darkblue{color: #314577;-webkit-print-color-adjust:exact;}
        .font-weight-bold{font-weight:700 !important;}
        .font-italic{font-style:italic;}
        .text-center{text-align:center !important;}
        .text-right{text-align:right !important;}
        .text-left{text-align:left !important;}
        .pg-break-after{page-break-after: always;}
        .table{width:100%;border-collapse: collapse;}
         .border-bottom-dash{border-bottom:1px dashed #314577;}
         .border-top-dash{border-top:1px dashed #314577;}
        strong{font-weight:bold;}
        .p-0{ padding:0px;}
        .p-2{padding:2px;}
        .p-5{padding:5px;}
        .p-10{padding:10px;}
        .p-20{padding:20px;}
        .p-l-5{padding-left:5px;}
        .p-l-10{padding-left:10px;}
        .p-l-15{padding-left:15px;}
        .p-r-10{padding-right:10px !important;}
        .p-r-15{padding-right:15px !important;}
        .p-r-20{padding-right:20px !important;}
        .p-r-30{padding-right:30px !important;}
        .pl-0{padding-left:0px !important;}
        .pr-0{padding-right:0px !important;}
        .p-t-5{padding-top:5px;}
        .p-t-10{padding-top:10px;}
        .p-b-5{padding-bottom:5px;}
        .p-b-10{padding-bottom:10px;}
        .p-b-20{padding-bottom: 20px !important;}
        .m-t-30{margin-top:30px !important;}
        .m-t-60{margin-top:60px !important;}
        .my-7{margin-bottom:7px !important; margin-top:7px !important;}
        .m-5{margin:5px;}
        .m-l-15{margin-left:15px !important;}
        .m-l-10{margin-left:10px !important;}
        .m-r-10{margin-right:10px !important;}
        .m-r-12{margin-right:12px !important;}
        .m-r-15{margin-right:15px !important;}
        .m-0{ margin:0px;}
        .m-b-0{margin-bottom: 0px !important;}
        .m-b-5{margin-bottom:5px !important;}
        .m-b-20{margin-bottom:20px !important;}
        .m-t-0{margin-top: 0px !important;}
        .m-t-5{margin-top:5px;}
        .m-t-20{margin-top:20px !important;}
        .m-t-10{margin-top:10px !important; }
        .m-t-15{margin-top:15px;}
        .m-l-5{margin-left:5px !important}
        .mr-2{margin-right:10px;}
        .mr-15{margin-right:15px;}
        table{width:100%;}
        .bg3{background: #314577; -webkit-print-color-adjust: exact;}
        .bg5 {background-color: #def6ff !important; -webkit-print-color-adjust: exact;}
        .table > thead.bg3 > tr > th { color: #ffffff; -webkit-print-color-adjust: exact; text-align:left; padding-left:5px;}
        .title-blue{ color: #314577;}
        .logo-title{color:#52beea;}
        td{vertical-align:top;}
        td{padding-left:5px;}
        .border-bottom-dash{border-bottom:1px dashed #314577;}
        .border-top-dash{border-top:1px dashed #314577;}
        .report-logo-w{width:25%;}
        </style>
      </head>
      <body>${content}</body>
    </html>`;

    htmlToPdf().set({
      margin: 0.5,
      filename: 'my_file.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, scale: 2, letterRendering: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    }).from(contentFull).outputPdf().outputPdf().then((pdf) => {
      var base64 = btoa(pdf)
      const blob = this.base64ToBlob(base64, 'application/pdf');
      const url = URL.createObjectURL(blob);
      const pdfWindow = window.open("");
      pdfWindow.document.write("<iframe width='100%' height='100%' src='" + url + "'></iframe>");

    }).catch(error => console.log(error));

  }

  base64ToBlob(base64, type = "application/octet-stream") {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: type });
  }

  generateUuid(isWithHyphens: boolean = true) {
    return isWithHyphens ? uuid() : uuid().replaceAll('-', '');
  }

  novasearchDropDownValidator(selectedDropDownValue: any, fromGroup: FormGroup, controlName: string) {
    if (selectedDropDownValue == "selected") {
      if (fromGroup.controls[controlName].hasError('optionNotSelected')) {
        fromGroup.controls[controlName].setErrors(null);
        fromGroup.updateValueAndValidity();
      }
    } else if (selectedDropDownValue == "empty") {
      if (fromGroup.controls[controlName].hasError('optionNotSelected')) {
        delete fromGroup.controls[controlName].errors['optionNotSelected'];
        fromGroup.updateValueAndValidity();
      }
    } else if (selectedDropDownValue == "not-found") {
      fromGroup.controls[controlName].setErrors({ optionNotSelected: true });
    } else if (selectedDropDownValue == "found-but-not-selected") {
      fromGroup.controls[controlName].setErrors({ optionNotSelected: true });
    }
  }

  clearValidationErrors() {
    this.validationToasters.forEach(validationToaster => {
      this._toastrService.clear(validationToaster.toastId);
    });
    this.validationToasters = [];
  }

  showValidationError(messageKey: any, clearExistingMessages: boolean = false) {
    if (clearExistingMessages) this.clearValidationErrors();
    const ref = this._toastrService.warning(
      messageKey instanceof Array ? messageKey.map(e => this.Translate(e)).join(", ") : this.Translate(messageKey),
      this.Translate("errorTitle"),
      {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right',
        toastClass: 'ngx-toastr toast-validation-error',
      }
    );
    this.validationToasters.push(ref);
  }

  validateForm(form: FormGroup, validationMessages?: any) {
    this.clearValidationErrors();
    if (form.invalid) {
      let errors = this.getValidationErrors(form, validationMessages);
      errors = [...new Set(Array.prototype.concat.apply([], errors))];
      errors.forEach(error => {
        this.showValidationError(error);
      });
    }
    return form.valid;
  }

  private getValidationErrors(form: FormGroup, validationMessages?: any) {
    let errors = [];
    Object.keys(form.controls).forEach(key => {
      if (form.controls[key] instanceof FormArray) {
        form.controls[key]['controls'].forEach((formGroup) => {
          let subErrors = this.getValidationErrors(formGroup, validationMessages);
          errors = errors.concat(subErrors);
        });
      } else {
        const controlErrors: ValidationErrors = form.get(key).errors;
        if (controlErrors != null) {
          if (validationMessages) {
            errors.push(
              Object.keys(controlErrors).map(keyError => {
                switch (keyError) {
                  case "required":
                    return `common.validations.missingData`;
                  case "minlength":
                    return validationMessages[key] ? `${validationMessages[key]['minLength']}` : '';
                  case "maxlength":
                    return validationMessages[key] ? `${validationMessages[key]['maxLength']}` : '';
                  case "pattern":
                    return validationMessages[key] ? `${validationMessages[key]['pattern']}` : '';
                  case "email":
                    return validationMessages[key] ? `${validationMessages[key]['email']}` : '';
                  case "min":
                    return validationMessages[key] ? `${validationMessages[key]['min']}` : '';
                  case "max":
                    return validationMessages[key] ? `${validationMessages[key]['max']}` : '';
                  case "validatePrecentage":
                    return validationMessages[key] ? `${validationMessages[key]['validatePrecentage']}` : '';
                  case "ambitoDuplicated":
                    return validationMessages[key] ? `${validationMessages[key]['ambitoDuplicated']}` : '';
                  case "optionNotSelected":
                    return `common.novasearch_validator`;
                  case "ngbDate":
                    return Object.keys(controlErrors[keyError]).map(ngbError => {
                      switch (ngbError) {
                        case 'invalid':
                          return validationMessages[key] && validationMessages[key]['ngbDate'] ? `${validationMessages[key]['ngbDate']['invalid']}` : '';
                        case 'requiredAfter':
                          return validationMessages[key] && validationMessages[key]['ngbDate'] ? `${validationMessages[key]['ngbDate']['requiredAfter']}` : '';
                        case 'requiredBefore':
                          return validationMessages[key] && validationMessages[key]['ngbDate'] ? `${validationMessages[key]['ngbDate']['requiredBefore']}` : '';
                      }
                    })
                }
              })
            );
          }
        }
      }
    });
    return errors;
  }

}
