import { UserAction } from './../../../models/userAction.model';
import { DbChangeHistoryEndPoints, DoctorEconomicActsEndPoints, UserActionsEndPoints } from './../../../constants/api-endpoints';
import { Town } from 'src/app/models/doctor/doctor-town.model';
import { Province } from '../../../models/doctor/province.model';
import { Country } from '../../../models/country.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import {
  DoctorEndPoints,
  CouncilEndPoints,
  DoctorTypeEndPoints,
  MailAttentionEndPoints,
  TownEndPoints,
  DoctorDegreeEndPoints,
  CountryEndPoints,
  ProvinceEndPoints,
  PostEndPoints,
  DedicationEndPoints,
  ExclusiveEndPoints,
  WorkingClinicEndPoints,
  WorkingSectorEndPoints,
  SocietyUrlEndPoints,
  RecallTypeEndPoints
} from '../../../constants/api-endpoints';
import { Doctor } from '../../../models/doctor/doctor.model';
import { Council } from '../../../models/doctor/council.model';
import { DoctorType } from '../../../models/doctor/doctorType.model';
import { MailAttention } from '../../../models/doctor/mail-attention.model';
import { DoctorDegree } from '../../../models/doctor/doctor-degree.model';
import { WorkingClinic } from '../../../models/doctor/working-clinic.model';
import { WorkingSector } from '../../../models/doctor/working-sector.model';
import { Exclusive } from '../../../models/doctor/exclusive.model';
import { Post } from '../../..//models/doctor/post.model';
import { Dedication } from '../../../models/doctor/dedication.model';
import { Societies } from '../../../models/doctor/societies.model';
import { RecallType } from '../../../models/dashboard/recall-type.model';
import { ApiService } from '../../common/services/api.service';


@Injectable({
  providedIn: 'root'
})
export class DoctorService {

  doctorUrl = DoctorEndPoints;

  exclusiveUrl = ExclusiveEndPoints;
  dedicationUrl = DedicationEndPoints;
  countryUrl = CountryEndPoints;
  degreeUrl = DoctorDegreeEndPoints;
  provinceUrl = ProvinceEndPoints;
  postUrl = PostEndPoints;
  doctorTypeUrl = DoctorTypeEndPoints;
  workingClinicUrl = WorkingClinicEndPoints;
  mailAttentionUrl = MailAttentionEndPoints;
  townUrl = TownEndPoints;
  workingSectorUrl = WorkingSectorEndPoints;
  councilUrl = CouncilEndPoints;
  societiesUrl = SocietyUrlEndPoints;
  recallTypeUrl = RecallTypeEndPoints;
  userActionsUrl = UserActionsEndPoints;
  dbChangeHistoryUrl = DbChangeHistoryEndPoints;
  doctorEconomicActsEndPoints = DoctorEconomicActsEndPoints;
  isCurrentPage = false;
  isReload = false;
  isLoading = false;
  paymentsCount = 0;

  constructor(private _apiService: ApiService) { }

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  // ---doctor api methods---
  deleteDoctor(doctorId: string): Observable<Doctor> {
    let url = this.doctorUrl.updateDoctorGeneric;
    url = url.replace('{id}', '');
    return this._apiService.delete(url, doctorId.toString(), true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getDoctorListPaginated(pageSize: number, page: number, _status: string, filters): Observable<Doctor> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('filter', filters)

    const query = params.toString().replace('+', '%20');
    return this._apiService.get(this.doctorUrl.doctorListPagination + '?' + `${query}`, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  generateExcel(pageSize: number, page: number, _status: string, filters) {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('filter', filters);

    const query = params.toString().replace('+', '%20');

    return this._apiService.get(this.doctorUrl.generateDoctorsListExcel + `?${query}`, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));

  }

  getDoctorList(): Observable<Doctor> {
    return this._apiService.get(this.doctorUrl.getDoctorList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  doctorList(): Observable<Doctor> {
    return this._apiService.get(this.doctorUrl.doctorList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getADoctorData(id: string): Observable<Doctor> {
    let url = this.doctorUrl.getDoctorData;
    url = url.replace('{id}', id);
    return this._apiService.get(url, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveDoctorDetails(doctorDetails: Doctor): Observable<Doctor> {
    return this._apiService.post(this.doctorUrl.addDoctor, doctorDetails, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateDoctorDetails(doctorDetails: Doctor): Observable<Doctor> {
    let url = this.doctorUrl.updateDoctorGeneric;
    url = url.replace('{id}', doctorDetails.id.toString());
    return this._apiService.put(url, doctorDetails, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  // ---auxiliary api methods---

  getDoctorCouncilChangeHistoryData(referenceId: number): Observable<Council> {
    let url = this.dbChangeHistoryUrl.getDbChangeHistoriesByEntity;
    url = url.replace('{referenceId}', referenceId.toString());
    url = url.replace('{entity}', 'DoctorCouncil');
    return this._apiService.get(url, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteDoctorCouncilChangeHistoryData(id: string): Observable<any> {
    let url = this.dbChangeHistoryUrl.deleteDbChangeHistory;
    return this._apiService.delete(url, id, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateDoctorCouncilChangeHistoryData(dbChangeHistory): Observable<any> {
    let url = this.dbChangeHistoryUrl.updateDbChangeHistory + dbChangeHistory.id;
    return this._apiService.put(url, dbChangeHistory, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getCouncilList(): Observable<Council> {
    return this._apiService.get(this.councilUrl.getCouncilList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getRecallTypeList(): Observable<RecallType> {
    return this._apiService.get(this.recallTypeUrl.getRecallTypeList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getDoctorTypeList(): Observable<DoctorType> {
    return this._apiService.get(this.doctorTypeUrl.getDoctorTypeList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getMailAttentionList(): Observable<MailAttention> {
    return this._apiService.get(this.mailAttentionUrl.getMailAttentionList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getTownList(isActiveOnly: boolean = false): Observable<Town> {
    return this._apiService.get(this.townUrl.getTownList + "?activeOnly=" + isActiveOnly, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getSocietiesList(): Observable<Societies> {
    return this._apiService.get(this.societiesUrl.getDoctorSociety, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }


  getProvinceList(): Observable<Province> {
    return this._apiService.get(this.doctorUrl.provinceList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getCountryList(): Observable<Country> {
    return this._apiService.get(this.countryUrl.getCountryList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getWorkingClinicList(): Observable<WorkingClinic> {
    return this._apiService.get(this.workingClinicUrl.getWorkingClinicList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getWorkingSectorList(): Observable<WorkingSector> {
    return this._apiService.get(this.workingSectorUrl.getWorkingSectorList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getExclusiveList(): Observable<Exclusive> {
    return this._apiService.get(this.exclusiveUrl.getExclusiveList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getDedicationList(): Observable<Dedication> {
    return this._apiService.get(this.dedicationUrl.getDedicationList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getDegreeList(): Observable<DoctorDegree> {
    return this._apiService.get(this.degreeUrl.getDoctorDegreeList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getPostList(): Observable<Post> {
    return this._apiService.get(this.postUrl.getPostList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getLatestCollegiateNumber(id: number): Observable<Doctor> {
    return this._apiService.get(this.doctorUrl.getLatestCollegiateNumberAccordingToPostalcode + id, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getDoctorStatistics(provinces: string): Observable<Doctor> {
    const params = new HttpParams().set('provinces', provinces);
    const query = params.toString().replace('+', '%20');
    return this._apiService.get(this.doctorUrl.getDoctorStatistics + `?${query}`, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  exportDoctorStatistics(provinces: string): Observable<Doctor> {
    const params = new HttpParams().set('provinces', provinces);
    const query = params.toString().replace('+', '%20');
    return this._apiService.get(this.doctorUrl.exportDoctorStatistics + `?${query}`, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveUserAction(userAction: UserAction): Observable<UserAction> {
    return this._apiService.post(this.userActionsUrl.saveUserAction, userAction, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateDoctorSendCorrespondenceType(doctorId: number, sendCorrespondenceType: number): Observable<Doctor> {
    return this._apiService.put(this.doctorUrl.updateDoctorSendCorrespondenceType + "/" + doctorId + "/" + sendCorrespondenceType, null, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getEconomicActsByDoctor(pageSize: number, page: number, filter) {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('filter', filter.originId + ';' + filter.collegiateIds + ';' + (filter.fromDate || '') + ';' + (filter.toDate || '') + ';' + filter.type + ';' + filter.economicActsIds + ';' + filter.paymentId);
    const query = params.toString().replace('+', '%20');
    return this._apiService.get(this.doctorEconomicActsEndPoints.doctorEconomicActList + '?' + `${query}`, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

}
