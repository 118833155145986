import { Town } from './doctor-town.model';
import { DoctorCurrentNationality } from './doctor-current-nationality.model';
import { DoctorGender } from './doctor-gender.model';
import { DoctorNationality } from './doctor-nationality.model';
import { Province } from './province.model';
import { DoctorProvince } from './doctor-province.model';
import { DoctorCountry } from './doctor-country.model';
import { DoctorClinic } from './doctor-clinic.model';

export class Doctor {
  name: string;
  surname1: string;
  surname2: string;
  street: string;
  provinceName: string;
  townId: number;
  provinceId: number;
  countryId: number;
  country: DoctorCountry;
  personalTelephone: string;
  workTelephone: string;
  mobileNumber: string;
  fax: string;
  email: string;
  web: string;
  number: string;
  typeOfVia: string;
  nameOfVia: string;
  door: string;
  postalCode: string;
  birthOfDate: string;
  genderId: number;
  nif: string;
  nie: string;
  passport: string;
  birthPlace: string;
  birthProvince: DoctorProvince;
  birthTown: Town;
  birthProvinceId: number;
  birthTownId: number;
  nationalityByBirthId: number;
  currentNationalityId: number;
  imageName: string;
  imagePathUrl: string;
  floorNumberOfBuilding: string;
  town: Town; // = <Town>{};
  currentNationality: DoctorCurrentNationality;
  gender: DoctorGender;
  nationalityByBirth: DoctorNationality;
  doctorBilling: [];
  doctorCorrespondence: [];
  doctorCouncil: [];
  doctorCurriculum: [];
  doctorHistory: [];
  doctorObservations: [];
  doctorProfessional: [];
  doctorSanctions: [];
  doctorClinic: DoctorClinic[];
  province: Province;
  id: number;
  collegiateNumber: number;
  townName: string;
  doctorRegisteredDate: Date;
  status: boolean;
  genderName: string;
  birthDate: any;
  doctorTypeName: string;
  registeredDate: any;
  unregisteredDate: any;
  sendCorrespondenceType: any;
  hasDocuments: boolean;
  documentTypeId?: number;
  collegeProvinceId?: number;
  collegiateNumberPrefix: number;
  collegiateNumberSuffix: number;

  constructor() {
    this.province = new Province();
    this.town = new Town();
    this.gender = new DoctorGender();
    this.birthProvince = new DoctorProvince();
    this.birthTown = new Town();
    this.currentNationality = new DoctorCurrentNationality();
    this.nationalityByBirth = new DoctorNationality();

  }
}
